import './Footer.scss';

export function Footer() {
    return (
        <footer class="footer">
            <div class="footer-container">
                <p>© Ashley Leal 2024
                <br></br>
                mail@ashleyleal.ca
                </p>
            </div>
        </footer>
    );
}