import './Projects.scss';
import Navbar from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';


export default function Projects() {
    return (
        <>
            <Navbar />
            <Footer />
        </>
    );
}