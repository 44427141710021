import './Navbar.scss';
import desktopLogo from '../../assets/navbar-logo-desktop.svg';

export default function Navbar() {
    return (
        <nav class="navbar">
            <div class = "navbar-list">
                {/* <div class = "navbar-item">
                    <a href = "/#about" class = "navbar-link">about</a>
                </div>
                <div class = "navbar-item">
                    <a href = "projects" class = "navbar-link">projects</a>
                </div> */}
                <div class = "navbar-item">
                    <a href = "/" class = "navbar-img"> <img src = {desktopLogo} alt = "logo" /> </a>
                </div>
                {/* <div class = "navbar-item">
                    <a href = "resume" class = "navbar-link">resume</a>
                </div>
                <div class = "navbar-item">
                    <a href = "contact" class = "navbar-link">contact</a>
                </div> */}
            </div>
        </nav>
    );
}
