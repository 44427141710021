import './Home.scss';
import Navbar from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';
import { HoverImage } from '../../components/HoverImage/HoverImage';

import halfToneStars1 from '../../assets/halftone-stars1.svg';
import halfToneStars2 from '../../assets/halftone-stars2.svg';
import halfToneStars3 from '../../assets/halftone-stars3.svg';

import meHalftone from '../../assets/me-halftone.svg';
import meNormal from '../../assets/me-normal.svg';

import alien from '../../assets/alien.svg'; 

import github from '../../assets/github-icon-purple.svg';
import linkedin from '../../assets/linkedin-icon-purple.svg';
import youtube from '../../assets/youtube-icon-purple.svg';


export default function Home() {
  return (
    <>
      <Navbar />

      <div className="background-image-1">
        <img src={halfToneStars1} alt="background" />
      </div>

      <div className="home" id="home">

        <div className="home-container">
          <h2>Hi, I'm</h2>
          <h1>Ashley Leal</h1>
          <p>A computer engineering student at the University of Toronto with a relentless passion for technology and problem-solving.</p>
        </div>
      </div>

      <div className="background-image-2">
        <img src={halfToneStars2} alt="background" />
      </div>

      <div className="about" id="about">
        <div className="about-container-1">

          <div className= "about-box-1">

            <div className="about-box-text">

              <h2>About Me</h2>

              <p>My name is Ashley and I’m currently working towards a major in Computer Engineering, along with minors in Artificial Intelligence and Business, at the University of Toronto.
              <br></br><br></br>
              In my extracurricular activities, including my role as Director of the Technical Team at <a href="https://ieee.utoronto.ca/" target="_blank" rel="noreferrer">IEEE UofT</a>, Networking Facilitator for <a href="https://www.facebook.com/groups/ece496/" target="_blank" rel="noreferrer">
              ECE Capstone projects</a>, 
              and Webmaster for <a href="https://orientation.skule.ca/"target="_blank" rel="noreferrer">UofT Engineering’s orientation website</a>, I blend my technical skills with leadership to drive impactful projects. 
              And when I’m not attending classes, you can probably find me at a hackathon.
              <br></br><br></br>
              Outside of my academic pursuits, I enjoy drawing, playing video games, <br></br> 
              and inline skating, which provide a balance to my technical endeavors. 
              <br></br><br></br>
              Welcome to my website, where you can learn more about me and <br></br>
              explore my projects that showcase my passion for <br></br>
              engineering and innovation.
              </p>

            </div>

            <div className="me-box-halftone">
            <HoverImage src={meHalftone} alt="me" hoverText={"This is me @Hack the Student Life 2024 😎"}/>
            </div>  

            <div className="me-box-normal">
              <img src={meNormal} alt="me" />
            </div>  

          </div>

          <div className= "about-box-2">
            <h2>Links</h2>

            <div className= "links-container">
            <a href = "https://github.com/ashleyleal" target="_blank" rel="noreferrer" class ="link"> <img src = {github} alt = "logo" /> </a>
            <a href = "https://www.linkedin.com/in/ashleynicoleleal/" target="_blank" rel="noreferrer" class ="link"> <img src = {linkedin} alt = "logo" /> </a>
            <a href = "https://www.youtube.com/@ashn.c" target="_blank" rel="noreferrer" class ="link"> <img src = {youtube} alt = "logo" /> </a>
            </div>

          </div>

          <div className= "about-box-3">

            <div className="about-box-3-text">
              <p>My current key areas of interest are in software, embedded systems, AI, and the intersection between engineering and business. You can learn more about my skills by browsing <a href='https://github.com/ashleyleal' target="_blank" rel="noreferrer">my Projects.</a></p>
            </div>

          </div>
        
        </div>

        <div className="about-container-2">
        <div className= "about-box-4">
          
          <img src={alien} alt="alien" />

          <div className="about-box-4-text">
          <h2>Fun fact</h2>
          <p>My first programming language was Lua, which I started teaching myself in 2015. Game development made me fall in love with software engineering!</p>
          </div>

          </div>

          <div className= "about-box-5">
            <div className="about-box-5-text">
              <p>I am currently seeking engineering internships for summer 2025 and beyond. Please feel free to <a href='https://www.linkedin.com/in/ashleynicoleleal/' target="_blank" rel="noreferrer">connect with me!</a> </p>
          </div>
        </div>
      </div>

      </div>

      <div className="background-image-3">
        <img src={halfToneStars3} alt="background" />
      </div>


      <Footer />
      </>
  );
}
