import './Contact.scss';
import Navbar from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer/Footer';

export default function Contact() {
  return (
    <>
    <Navbar />
    <Footer />
</>
  );
}